import { FC, useEffect } from 'react';
import ConditionsProperties from '../../../form-builder/ConditionsProperties';
import { EditorProps } from '../../../form-builder/FormBuilderTypes';
import PreviewProperties from '../../../form-builder/PreviewProperties';

const SectionDividerEditor: FC<EditorProps> = (props) => {
  const { action, patchAction } = props;

  useEffect(() => {
    if (!action.noninteractive) {
      patchAction({
        noninteractive: true,
      });
    }
  }, [action.noninteractive, patchAction]);

  return (
    <div>
      <ConditionsProperties {...props} />

      <PreviewProperties {...props} />
    </div>
  );
};

export default SectionDividerEditor;
